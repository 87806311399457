<script setup lang="ts">
import type { SharedOffcanvas } from '~/components/shared/SharedOffcanvas.vue';

withDefaults(
    defineProps<{
        hideHelpService?: boolean;
    }>(),
    {
        hideHelpService: false,
    },
);

const offcanvas = inject<SharedOffcanvas>('offcanvas') as SharedOffcanvas;
const localePath = useLocalePath();
</script>

<template>
    <div class="relative bg-white">
        <div class="container flex justify-between py-2 lg:py-4">
            <div class="flex flex-wrap items-center">
                <NuxtLink
                    :to="localePath('/')"
                    class="flex flex-wrap relative"
                    :title="$t('home')"
                >
                    <SvgoLogoAhSmall class="text-brand-primary h-11 lg:hidden" />

                    <SvgoLogoAhClaim class="text-brand-primary hidden lg:block h-14" />
                </NuxtLink>
            </div>

            <div
                v-if="!hideHelpService"
                class="flex flex-wrap items-center"
            >
                <div class="text-lg leading-5 lg:flex">
                    <SharedButton
                        color="transparent"
                        size="sm"
                        :title="$t('helpAndService')"
                        :aria-label="$t('helpAndService')"
                        :link="true"
                        @click="offcanvas.open('AccountServiceMenu', 'layout-header-service-menu')"
                    >
                        <SvgoUserHeadset class="w-auto h-4 mr-2" /> {{ $t('helpAndService') }}
                    </SharedButton>

                    <SharedOffcanvasContent
                        id="layout-header-service-menu"
                        :offcanvas="offcanvas"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
